/**
 * Name: Map
 */

export default class Map {

  constructor (element, options = {}) {
    this.element = element;
    this.leaflet = null;
    this.leafletIsLoading = false;
    this.leafletIsLoaded = false;
    this.coordinates = JSON.parse(this.element.dataset.map);
  }

  loadLeaflet() {
    if (!this.leafIsLoading && !this.leafIsLoaded && typeof L === 'undefined') {
      this.leafIsLoading = true;

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
      document.body.appendChild(link);

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.js';
      script.addEventListener('load', () => {
        this.leafIsLoading = false;
        this.leafIsLoaded = true;
        this.leaflet = window.L;
        this.handleMap();
      }, { once: true });
      document.body.appendChild(script);
    }
  }

  handleMap() {
    this.map = this.leaflet.map(this.element, {
      center: [this.coordinates.lat, this.coordinates.lng],
      zoom: 14
    });

    this.leaflet.tileLayer('https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}@2x.png?key=dSl9xUo8KormPT5EcrYw', {
      attribution: '<a href=\"https://www.maptiler.com/\" target=\"_blank\">MapTiler</a>',
      maxZoom: 20
    }).addTo(this.map);

    const icon = this.leaflet.divIcon({
      className: '',
      html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="32" height="32"><use xlink:href="/app/themes/default/assets/images/sprite.svg#sprite-marker"></use></svg>`,
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    this.marker = this.leaflet.marker([this.coordinates.lat, this.coordinates.lng], { icon: icon });
    this.marker.on('click', (e) => { window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.coordinates.lat},${this.coordinates.lng}`) });
    this.marker.addTo(this.map);
  }

  mount () {
    if (this.coordinates) {
      this.loadLeaflet();
    }
  }
}
