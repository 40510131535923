import 'mdn-polyfills/NodeList.prototype.forEach';
import Map from './Map';

document.addEventListener('DOMContentLoaded', () => {
  const maps = document.querySelectorAll('[data-map]');
  if (!maps.length) {
    return;
  }

  maps.forEach((element) => {
    const maps = new Map(element);
    maps.mount();
  });
});
